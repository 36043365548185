<template>
  <div class="bindingPhone" v-if="showBindingPhone">
    <div class="close" @click="close">
      <i class="el-icon-close"></i>
    </div>

    <div class="logo">
      <img :src="require('@/assets/images/logo.png')" alt="" />
    </div>

    <div class="content">
      <img
        v-if="!isinp_box"
        class="weixin_logo"
        :src="require('assets/images/login/weixin_logo.png')"
        alt=""
      />

      <div class="box" v-if="isinp_box">
        <div class="title">请绑定手机号</div>

        <div class="inp_box">
          <img :src="require('assets/images/login/login-yonghu.png')" alt="" />
          <input
            type="text"
            name="mobile"
            maxlength="11"
            @input="phone = phone.replace(/[^0-9]/g, '')"
            v-model="phone"
            placeholder="请输入手机号"
          />
        </div>
        <div class="inp_box">
          <img :src="require('assets/images/login/login-codeico.png')" alt="" />
          <input
            type="text"
            name="mobile"
            maxlength="6"
            v-model="authCode"
            placeholder="请输入验证码"
          />

          <div class="span" @click="sendVerif" v-show="verif_seconds == 60">
            获取验证码
          </div>
          <div class="span" v-show="verif_seconds < 60">
            已发送({{ verif_seconds }})
          </div>
        </div>

        <div class="btn" @click="binding">绑定</div>
      </div>
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import qs from "qs";
import { getMobileAchCaptchas, checkMobileAchCaptchas } from "api/common";
import { weixinLogin, verify, getuserInfo } from "api/service";
import Cookies from "js-cookie";
export default {
  name: "bindingPhone",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      isinp_box: true,
      verif_seconds: 60,
      // 手机号
      phone: "",
      // 手机验证码
      authCode: "",
      // code id
      codeID: "",
      // 微信扫码code
      code: "",
      unionid: "",
      timer: null,
      showBindingPhone: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    close() {
      this.showBindingPhone = false;
    },

    // 发送验证码
    async sendVerif() {
      if (this.timer != null) {
        return;
      }
      let reg = /^1[0-9]{10}$/;
      if (!reg.test(this.phone)) {
        this.$message({
          type: "error",
          message: "请输入正确的手机号!",
        });
        return;
      }

      const data = {
        type: 5,
        mobile: this.phone,
      };
      this.timer = setInterval(() => {
        if (this.verif_seconds > 0) {
          this.verif_seconds--;
        } else {
          clearInterval(this.timer);
          this.verif_seconds = 60;
          this.timer = null;
        }
      }, 1000);
      const res = await getMobileAchCaptchas(data);
      if (res.code != 200) {
        this.$message({
          type: "error",
          message: res.message,
        });
        return;
      }

      this.codeID = res.data.codeId;
    },

    // 绑定
    async binding() {
      if (!this.phone.trim()) {
        this.$message({
          type: "error",
          message: "请输入手机号",
        });
        return;
      }
      if (this.codeID == "") {
        this.$message({
          type: "error",
          message: "请获取验证码",
        });
        return;
      }
      if (!this.authCode.trim()) {
        this.$message({
          type: "error",
          message: "请输入验证码",
        });
        return;
      }

      const data = {
        mobile: this.phone,
        codeId: this.codeID,
        code: this.authCode,
      };

      const res = await checkMobileAchCaptchas(data);
      if (res.code != 200) {
        this.$message({
          type: "error",
          message: res.message,
        });

        return;
      }
    },

    // 微信code校验
    async wxVerify(code) {
      this.showBindingPhone = true;
      this.isinp_box = false;
      const data = {
        code,
        platform: 4,
      };

      const res = await verify(qs.stringify(data));
      if (res.code == 2024) {
        if (res.data.unionid) {
          this.unionid = res.data.unionid;
          this.isinp_box = true;
        } else {
          this.$message.error("code已经失效,请重新扫码!");
          this.$store.commit("setIsShowLogin", true);
        }
        return;
      }

      if (res.code != 200) {
        this.$message.error("发现意外错误,请换其他登录方式");
        this.$store.commit("setIsShowLogin", true);
        return;
      }

      this.unionid = res.data.unionid;
      this.weixinlogin();
    },

    // 认证登录
    async weixinlogin() {
      const data = {
        grant_type: "wechat_open",
        scope: "all",
        unionid: this.unionid,
        telephone: this.phone,
      };

      try {
        const res = await weixinLogin(qs.stringify(data));
        Cookies.set("auth_token", JSON.stringify(res), {
          expires: 7,
          path: "/",
          domain: ".writemall.com",
        });
        this.$store.commit("setIsShowLogin", false);
        this.showBindingPhone = false;
        this.getUserinfo();
        setTimeout(() => {
          this.$router.push({ path: "/" });
        }, 300);
      } catch (error) {
        this.showBindingPhone = false;
        this.$message.error("发现意外错误,请换其他登录方式");
        this.$store.commit("setIsShowLogin", true);
      }
    },

    //获取用户信息
    async getUserinfo() {
      try {
        const res = await getuserInfo();
        if (res.code === 200) {
          this.$store.commit("setUserInfo", res.data);
        }
      } catch (e) {
        this.$message.error("获取用户信息失败");
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.bindingPhone {
  width: 380px;
  height: 360px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 999;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  box-sizing: border-box;

  .close {
    position: absolute;
    padding: 10px;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .logo {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    img {
      width: 130px;
      height: 45px;
    }
  }
  .content {
    margin-top: 10px;
    text-align: center;

    .weixin_logo {
      margin-top: 20px;
      width: 210px;
    }
    .box {
      box-sizing: border-box;
      align-items: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      // margin: 20vh 0;
      .title {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        font-family: PingFang SC;
        font-weight: bold;
        // margin-bottom: 30px;
      }
      .inp_box {
        box-sizing: border-box;
        margin-bottom: 18px;
        width: 298px;
        height: 43px;
        background: #f8f8f8;
        border-radius: 22px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        img {
          width: 16px;
          margin-right: 20px;
        }
        input {
          width: 100%;
          height: 100%;
          background-color: #f8f8f8;
        }

        .span {
          width: 110px;
          border-left: 1px solid #e8e8e8;
          font-size: 12px;
          cursor: pointer;
          padding: 5px 0 5px 10px;
          color: #ff6900;
        }
      }

      .btn {
        cursor: pointer;
        margin-top: 20px;
        width: 298px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        color: #fff;
        background: #4587ff;
        border-radius: 22px;
      }
    }
  }
}
</style>
