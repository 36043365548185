import axios from 'axios'
const isNewVersion = () => {
  let url = `/version.json`
  axios.get(url, { headers: { 'Cache-Control': 'no-cache' } }).then((res) => {
    if (res.status === 200) {
      let vueVersion = res.data.version
      let localVueVersion = localStorage.getItem('vueVersion')
      if (localVueVersion && localVueVersion != vueVersion) {
        localStorage.setItem('vueVersion', vueVersion)
        window.location.reload()
        return
      } else {
        localStorage.setItem('vueVersion', vueVersion)
      }
    }
  })
}

export default {
  isNewVersion,
}
