import Cookies from "js-cookie";
const TOKEN_KEY = "auth_token";
export function getToken() {
  return Cookies.get(TOKEN_KEY);
}

export function isLogin() {
  if (!getToken()) {
    this.$store.commit("setIsShowLogin", true);
    return true;
  } else {
    return false;
  }
}
