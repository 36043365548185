import { render, staticRenderFns } from "./buyTips.vue?vue&type=template&id=659a666c&scoped=true&"
import script from "./buyTips.vue?vue&type=script&lang=js&"
export * from "./buyTips.vue?vue&type=script&lang=js&"
import style0 from "./buyTips.vue?vue&type=style&index=0&id=659a666c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "659a666c",
  null
  
)

export default component.exports