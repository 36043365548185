<template>
  <div class="shadow">
    <!-- $store.state.isShowLogin -->
    <div class="login" v-if="$store.state.isShowLogin">
      <div class="close" @click="closeLogin">
        <i class="el-icon-close"></i>
      </div>
      <!-- <div class="header-logo">
        <img :src="require('@/assets/images/logo.png')" alt="" />
      </div> -->
      <div class="login-main">
        <!-- 微信扫二维码 -->
        <div class="weixin">
          <div class="logo">
            <img :src="require('assets/images/cy_logo.png')" alt="" />
          </div>
          <div class="info">
            <div class="remark">
              <img :src="require('assets/images/gou.png')" alt="" />
              <span>AI成文 随意创作</span>
            </div>
            <div class="remark">
              <img :src="require('assets/images/gou.png')" alt="" />
              <span>专业文库 每日更新</span>
            </div>
            <div class="remark">
              <img :src="require('assets/images/gou.png')" alt="" />
              <span>优质模板 正版授权</span>
            </div>
            <div class="remark">
              <img :src="require('assets/images/gou.png')" alt="" />
              <span>闪亮金句 灵感源泉</span>
            </div>
          </div>
        </div>
        <div class="login-box">
          <div class="tab">
            <div
              class="tab-item"
              :class="{ active: currentMode == 1 }"
              @click="
                currentMode = 1;
                codeForm.phone = phoneForm.phone;
              "
            >
              密码登录
            </div>
            <div
              class="tab-item"
              :class="{ active: currentMode == 2 }"
              @click="
                currentMode = 2;
                phoneForm.phone = codeForm.phone;
              "
            >
              手机登录
            </div>
            <div
              class="tab-item"
              :class="{ active: currentMode == 3 }"
              @click="currentMode = 3"
            >
              微信登录
            </div>
          </div>
          <!-- 手机登录 -->
          <div class="phone-login" v-if="currentMode == 1">
            <div class="phone">
              <img :src="require('@/assets/images/user-blue.png')" alt="" />
              <input
                type="text"
                placeholder="请输入手机号"
                v-model="phoneForm.phone"
              />
            </div>
            <div class="password">
              <img :src="require('@/assets/images/suo-blue.png')" alt="" />
              <input
                :type="findPassWord ? 'text' : 'password'"
                placeholder="请输入密码"
                v-model="phoneForm.password"
              />
              <div class="view">
                <img
                  v-if="!findPassWord"
                  @click="findPassWord = !findPassWord"
                  :src="require('@/assets/images/login/changetype.png')"
                  alt=""
                />
                <img
                  v-if="findPassWord"
                  @click="findPassWord = !findPassWord"
                  :src="require('@/assets/images/login/yan-h.png')"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 短信登录 -->
          <div class="code-login" v-if="currentMode == 2">
            <div class="phone">
              <img :src="require('@/assets/images/user-blue.png')" alt="" />
              <input
                type="text"
                placeholder="请输入手机号"
                v-model="codeForm.phone"
              />
            </div>
            <div class="code">
              <img :src="require('@/assets/images/code-blue.png')" alt="" />
              <input
                type="text"
                maxlength="6"
                placeholder="请输入验证码"
                v-model="codeForm.code"
              />
              <div class="getCode" @click="sendVerif">
                {{
                  verif_seconds == 60
                    ? "发送验证码"
                    : `已发送(${verif_seconds})`
                }}
              </div>
            </div>
          </div>

          <!-- 微信登录 -->
          <div class="weixin-login" v-if="currentMode == 3">
            <div class="iframe-box">
              <iframe
                v-if="isShowCode"
                src="https://open.weixin.qq.com/connect/qrconnect?appid=wx0064c361acc150b7&scope=snsapi_login&redirect_uri=https://www.writemall.com/AuthRedirect&state=bind&login_type=jssdk&self_redirect=default&styletype=&sizetype=&bgcolor=&rst=&style=black&href=data:text/css;base64,LmltcG93ZXJCb3gge2Rpc3BsYXk6IGZsZXg7IGp1c3RpZnktY29udGVudDogY2VudGVyO30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEzOHB4OyBtYXJnaW4tdG9wOiAwcHg7IGJvcmRlcjogMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=="
                frameborder="0"
                scrolling="no"
                width="138px"
                height="138px"
                target="_top"
                sandbox="allow-scripts allow-top-navigation"
              ></iframe>
            </div>

            <div
              class="againGet"
              @click="
                () => {
                  isShowCodeClick();
                  isShowCode = false;
                }
              "
            >
              重新获取
            </div>
            <div class="tips">
              <img :src="require('assets/images/login/weixin.png')" alt="" />
              打开微信扫码即可登陆
            </div>
          </div>
          <!-- 密码操作 -->
          <div
            class="password-option"
            v-show="currentMode == 2 || currentMode == 1"
          >
            <div class="remember-password">
              <el-checkbox v-model="isRemember" label="记住密码"></el-checkbox>
            </div>
            <div class="forget-password" @click="showEdit">忘记密码</div>
          </div>
          <!-- 登录 -->
          <div
            class="confirm-login"
            v-show="currentMode == 2 || currentMode == 1"
          >
            <!-- <div class="go-register">
              还没有注册账号， <span class="register-text">立即注册</span>
            </div> -->
            <div class="login-btns" @click="confirmLogin">登录</div>
          </div>
          <div class="wx-login" v-if="currentMode != 3">
            <img
              v-if="!isiframe"
              :src="require('assets/images/wx-blue.png')"
              alt=""
              @click="isiframe = !isiframe"
            />
            <div v-if="!isiframe">微信登录</div>
            <div v-else class="wxcode">
              <iframe
                src="https://open.weixin.qq.com/connect/qrconnect?appid=wx0064c361acc150b7&scope=snsapi_login&redirect_uri=https://www.writemall.com/AuthRedirect&state=bind&login_type=jssdk&self_redirect=default&styletype=&sizetype=&bgcolor=&rst=&style=black&href=data:text/css;base64,LmltcG93ZXJCb3gge2Rpc3BsYXk6IGZsZXg7IGp1c3RpZnktY29udGVudDogY2VudGVyO30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEzOHB4OyBtYXJnaW4tdG9wOiAwcHg7IGJvcmRlcjogMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=="
                frameborder="0"
                scrolling="no"
                width="138px"
                height="138px"
                target="_top"
                sandbox="allow-scripts allow-top-navigation"
              ></iframe>
              <div
                @click="
                  () => {
                    isiframeFun();
                    isiframe = false;
                  }
                "
              >
                重新获取
              </div>
            </div>
          </div>
          <div class="wx-login" v-else>
            <img
              :src="require('assets/images/phone-blue.png')"
              alt=""
              @click="currentMode = 1"
            />
            <div>手机登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getuserInfo,
  codeLogin,
  passwordLogin,
  getMobileAchCaptchas,
} from "@/api/service";

import Cookies from "js-cookie";
export default {
  data() {
    return {
      isiframe: false, //显示微信登录二维码
      isShowCode: true,
      findPassWord: false, //查看密码
      currentMode: 1, //当前登录方式
      isRemember: false, //记住密码

      verif_seconds: 60, //验证码倒计时
      timer: null, //计时器
      phoneForm: {
        phone: "",
        password: "",
      },
      codeForm: {
        phone: "",
        code: "",
        codeId: "",
      },
    };
  },
  created() {
    this.getUserinfo();
    const mobile = window.localStorage.getItem("mobile");
    const password = window.localStorage.getItem("password");
    if (mobile) {
      this.phoneForm.phone = mobile;
      this.phoneForm.password = password;
      this.isRemember = true;
    }
  },
  methods: {
    //重新获取二维码
    isiframeFun() {
      setTimeout(() => {
        this.isiframe = true;
      }, 800);
    },
    isShowCodeClick() {
      setTimeout(() => {
        this.isShowCode = true;
      }, 800);
    },
    //关闭登录窗口
    closeLogin() {
      this.$store.commit("setIsShowLogin", false);
    },
    showEdit() {
      this.$store.commit("setIsShowLogin", false);
      this.$store.commit("setIsShowEdit", true);
    },
    //发送验证码
    sendVerif() {
      if (this.timer) {
        return;
      }
      const data = {
        type: 5,
        mobile: this.codeForm.phone,
      };
      this.setVerifTime();
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.codeForm.codeId = res.data.codeId;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //发送验证码计时
    setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.verif_seconds > 0) {
            this.verif_seconds--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.verif_seconds = 60;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
    confirmLogin() {
      if (this.currentMode == 2) {
        this.verifLogin();
        return;
      }
      if (!this.phoneForm.password) {
        return this.$message({
          type: "error",
          message: "请输入密码!",
        });
      }
      const data = {
        mobile: this.phoneForm.phone,
        password: this.phoneForm.password,
      };
      if (this.isRemember) {
        window.localStorage.setItem("mobile", this.phoneForm.phone);
        window.localStorage.setItem("password", this.phoneForm.password);
      } else {
        window.localStorage.removeItem("mobile");
        window.localStorage.removeItem("password");
      }
      passwordLogin(data)
        .then((res) => {
          let data = res;
          localStorage.setItem("auth_token", JSON.stringify(data.access_token));
          data = JSON.stringify(data);
          //保存token
          Cookies.set("auth_token", data, {
            expires: 7,
            path: "/",
            domain: ".writemall.com",
          });
          this.$message({
            type: "success",
            message: "登录成功!",
          });
          this.getUserinfo();
          this.$store.commit("setIsShowLogin", false);

          window.location.reload(true);
        })
        .catch(() => {
          this.$message.error("手机号或密码错误");
        });
    },
    //验证码登录
    async verifLogin() {
      if (this.codeForm.codeId == "") {
        return this.$message({
          type: "error",
          message: "请获取验证码!",
        });
      }
      if (!this.codeForm.code) {
        return this.$message({
          type: "error",
          message: "请输入验证码!",
        });
      }
      const data = {
        mobile: this.codeForm.phone,
        code: this.codeForm.code,
        id: this.codeForm.codeId,
      };

      try {
        let res = await codeLogin(data);
        if (res) {
          let data = res;
          localStorage.setItem("auth_token", JSON.stringify(data.access_token));

          data = JSON.stringify(data);
          //保存token
          Cookies.set("auth_token", data, {
            expires: 7,
            path: "/",
            domain: ".writemall.com",
          });
          this.$message({
            type: "success",
            message: "登录成功!",
          });
          this.getUserinfo();

          this.$store.commit("setIsShowLogin", false);

          // window.location.reload();
          window.location.reload(true);
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: "验证码错误!",
        });
      }
    },
    //获取用户信息
    async getUserinfo() {
      try {
        const res = await getuserInfo();
        if (res.code === 200) {
          this.$store.commit("setUserInfo", res.data);
          console.log(res);
        }
      } catch (e) {
        this.$message.error("获取用户信息失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 580px;
  height: 450px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 999;
  box-shadow: 0px 0px 13px 5px rgba(155, 157, 170, 0.6);
  box-sizing: border-box;
  // padding: 30px 0;
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
    z-index: 99;
  }
  .header-logo {
    text-align: center;
    margin-bottom: 20px;
    img {
      width: 130px;
      height: 45px;
    }
  }
  .login-main {
    display: flex;
    height: 100%;
    .weixin {
      width: 232px;
      height: 100%;
      background-image: url("~@/assets/images/loginbg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .logo {
        margin: 30px 0 0 30px;
        img {
          width: 115px;
          height: 40px;
        }
      }
      .info {
        margin: 30px 0 0 30px;
        .remark {
          display: flex;
          align-items: center;
          margin: 20px 0;
          img {
            margin-right: 15px;
            width: 14px;
            height: 14px;
          }
          span {
            color: #eaeffd;
          }
        }
      }
    }
    .login-box {
      height: 100%;
      padding: 30px 0px;
      box-sizing: border-box;
      flex: 1;
      position: relative;
      .tab {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        .tab-item {
          font-size: 14px;
          position: relative;
          cursor: pointer;
        }
        .active::after {
          content: "";
          width: 15px;
          height: 2px;
          background: #4587dd;
          border-radius: 5px;
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .phone-login {
        img {
          width: 20px;
          height: 20px;
        }
        margin-top: 30px;
        > div {
          display: flex;
          align-items: center;
          margin: 20px auto;
          background: #f3f5f9;
          border-radius: 18px;
          height: 35px;
          width: 75%;
          padding: 0 15px;
          box-sizing: border-box;

          input {
            font-size: 14px;
            padding-left: 10px;
            color: #808080;
            border: none;
            outline: none;
            background: none;
            flex: 1;
          }
        }
        .password {
          .view {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
        }
      }
      .code-login {
        margin-top: 30px;
        > div {
          display: flex;
          align-items: center;
          margin: 20px auto;
          background: #f3f5f9;
          border-radius: 18px;
          height: 35px;
          width: 75%;
          padding: 0 15px;
          box-sizing: border-box;
          img {
            width: 20px;
            height: 20px;
          }
          input {
            font-size: 14px;
            padding-left: 10px;
            color: #808080;
            border: none;
            outline: none;
            background: none;
            flex: 1;
          }
        }
        .code {
          input {
            width: 110px;
          }
          .getCode {
            font-size: 12px;
            display: flex;
            align-items: center;
            color: #4587dd;
            width: 100px;
            height: 20px;
            border-left: 1px solid #ccc;
            padding-left: 10px;
            cursor: pointer;
          }
        }
      }
      .weixin-login {
        margin-top: 30px;
        text-align: center;
        .iframe-box {
          height: 150px;
        }
        .againGet {
          font-size: 13px;
          margin: 20px;
          cursor: pointer;
          color: #4587dd;
        }
        .tips {
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .password-option {
        width: 75%;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .remember-password {
          ::v-deep .el-checkbox {
            .el-checkbox__input.is-checked .el-checkbox__inner {
              border-color: #4587dd;
              background: #4587dd;
            }
            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #4587dd;
            }
          }
        }
        .forget-password {
          font-size: 14px;
          color: #666;
          cursor: pointer;
          &:hover {
            color: #4587dd;
          }
        }
      }
      .confirm-login {
        width: 100%;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .go-register {
          font-size: 13px;
          color: #666;
          display: flex;
          align-items: center;
          .register-text {
            color: #4587dd;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .login-btns {
          text-align: center;
          width: 220px;
          margin: 0 auto;
          font-size: 14px;
          color: #fff;
          background: #4587dd;
          padding: 8px 20px;
          border-radius: 18px;
          cursor: pointer;
        }
      }

      .wx-login {
        width: 100%;
        text-align: center;
        color: #999999;

        img {
          width: 46px;
          height: 46px;
          margin: 30px 0 10px 0;
          cursor: pointer;
        }
        .wxcode {
          div {
            // margin-top: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
