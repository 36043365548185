<template>
  <div id="app">
    <!-- 登录组件 -->
    <Login />
    <!-- 忘记秘密 -->
    <Forget />
    <!-- 扫码绑定手机号组件 -->
    <bindingPhone ref="bindingPhone" />
    <!-- Vip提示组件 -->
    <BuyTips />
    <buyTipsLock />
    <Power />
    <!-- 路由 -->
    <router-view />
    <!-- 激活兑换 -->
    <VipCode />
    <welfare />
    <!-- 绑定用户 -->
    <userList />
  </div>
</template>
<script>
import Login from "@/components/Login.vue";
import Forget from "@/components/Forget.vue";
// Forget
import bindingPhone from "@/components/bindingPhone.vue";
import welfare from "@/components/welfare";
import userList from "@/components/userList";
import buyTipsLock from "@/components/buyTipsLock.vue";
import BuyTips from "@/components/buyTips.vue";
import Power from "components/power.vue";
import VipCode from "@/components/VipCode.vue";
export default {
  components: {
    Login,
    buyTipsLock,
    BuyTips,
    bindingPhone,
    Power,
    welfare,
    Forget,
    userList,
    VipCode,
  },
  mounted() {
    this.getQueryVariable();
    window.openWelfare = () => {
      this.$bus.$emit("showGrit");
    };
  },
  methods: {
    // 获取微信登录code
    getQueryVariable() {
      var query = window.location.href;
      if (query.includes("code=")) {
        let code = query.split("code=")[1].split("&state")[0];
        this.$refs["bindingPhone"].wxVerify(code);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
