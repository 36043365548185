import axios from 'axios'
import { getToken } from '@/utils/auth'
import store from '../store/index'
import Vue from 'vue'

console.log(process.env.NODE_ENV);
let stateAxios = true
const myAxios = axios.create({
  baseURL:
    process.env.NODE_ENV == 'production'
      ? `${process.env.VUE_APP_BASE_API}crewordapi`
      : '/crewordapi',
  timeout: 300000,
})

myAxios.interceptors.request.use(
  (config) => {
    let TokenObj = getToken()
    TokenObj = TokenObj ? JSON.parse(TokenObj) : ''
    let Token = localStorage.getItem('auth_token')
      ? JSON.parse(localStorage.getItem('auth_token'))
      : ''
    if (TokenObj || Token) {
      config.headers.Authorization = TokenObj
        ? `${TokenObj.token_type} ${TokenObj.access_token}`
        : `bearer  ${Token}`
    } else {
      config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

myAxios.interceptors.response.use(
  (config) => {
    return config.data
  },
  async (error) => {
    if (stateAxios) {
      stateAxios = false
      if (store.state.exampleLoading) {
        await store.state.exampleLoading.close()
      }
      stateAxios = true
    }

    return Promise.reject(error)
  }
)

export default myAxios
