import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {}, //用户信息
    isShowLogin: false,
    isShowEdit: false,
    /* 编辑器Vuex */
    ocrContent: "", //编辑器选中的内容
    continueContent: "", //子组件续写的内容
    onlineBasePath: "https://fafs.antiplagiarize.com/",
    wordPath: "http://word.writemall.com",
    lockClickText: {},
    buyTipsInfo: {},
    sidebarActive: 0,
    currentWord: null,
    // 溯源内容
    rootsContent: "",
    // Loading 实例
    exampleLoading: "",
    // 绘画生成关键字
    AiImageKey: "",
  },
  getters: {},
  mutations: {
    setAiImageKey(state, data) {
      state.AiImageKey = data;
    },
    setExampleLoading(state, data) {
      state.exampleLoading = data;
    },
    setRootsContent(state, data) {
      state.rootsContent = data;
    },
    setIsShowLogin(state, data) {
      state.isShowLogin = data;
    },
    // isShowEdit
    setIsShowEdit(state, data) {
      state.isShowEdit = data;
    },
    setOcrContent(state, data) {
      state.ocrContent = data;
    },
    setContinueContent() {
      state.continueContent = data;
    },
    ChangeSideBarId(state, payload) {
      state.sidebarActive = payload;
    },
    setLockClickText(state, data) {
      state.lockClickText = data;
    },
    setBuyTipsInfo(state, data) {
      state.buyTipsInfo = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setCurrentWord(state, data) {
      console.log(data);
      state.currentWord = data;
    },
  },
  actions: {},
  modules: {},
  // plugins: [persistedState()],
});
