<template>
  <div class="Forget" v-if="$store.state.isShowEdit">
    <div class="close" @click="closeEdit">
      <i class="el-icon-close"></i>
    </div>
    <div class="header-logo">
      <img :src="require('@/assets/images/logo.png')" alt="" />
    </div>

    <div class="phone-login">
      <div class="phone">
        <img :src="require('@/assets/images/login/login-yonghu.png')" alt="" />
        <input
          type="text"
          placeholder="请输入手机号"
          v-model="phoneForm.phone"
        />
      </div>
      <div class="password">
        <img :src="require('@/assets/images/login/login-suo.png')" alt="" />
        <input
          :type="findPassWord ? 'text' : 'password'"
          placeholder="请输入密码"
          v-model="phoneForm.password"
        />
        <div class="view">
          <img
            v-if="!findPassWord"
            @click="findPassWord = !findPassWord"
            :src="require('@/assets/images/login/changetype.png')"
            alt=""
          />
          <img
            v-if="findPassWord"
            @click="findPassWord = !findPassWord"
            :src="require('@/assets/images/login/yan-h.png')"
            alt=""
          />
        </div>
      </div>
      <div class="code">
        <img :src="require('@/assets/images/login/login-codeico.png')" alt="" />
        <input
          type="text"
          maxlength="6"
          placeholder="请输入验证码"
          v-model="phoneForm.code"
        />
        <div class="getCode" @click="sendVerif">
          {{ verif_seconds == 60 ? "发送验证码" : `已发送(${verif_seconds})` }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="tips">
        已有账号,立即<span class="light" @click="goLogin">登录</span>
      </div>
      <div class="btn">
        <div class="edit" @click="toUpdate">修改</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMobileAchCaptchas, update } from "@/api/service";
export default {
  data() {
    return {
      isiframe: true, //显示微信登录二维码
      findPassWord: false, //查看密码
      currentMode: 1, //当前登录方式
      isRemember: false, //记住密码

      verif_seconds: 60, //验证码倒计时
      timer: null, //计时器
      phoneForm: {
        phone: "",
        password: "",
        code: "",
        codeId: "",
      },
    };
  },
  methods: {
    closeEdit() {
      this.$store.commit("setIsShowEdit", false);
    },

    goLogin() {
      this.$store.commit("setIsShowLogin", true);
      this.$store.commit("setIsShowEdit", false);
    },
    //发送验证码计时
    f_setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.verif_seconds > 0) {
            this.verif_seconds--;
          } else {
            clearInterval(this.timer);
            this.verif_seconds = 60;
            this.timer = null;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
    sendVerif() {
      if (this.timer) {
        return;
      }
      let reg = /^1[3|4|5|7|8]\d{9}$/;
      if (!reg.test(this.phoneForm.phone)) {
        this.$message({
          type: "error",
          message: "请输入正确的手机号!",
        });
        return false;
      }
      if (this.phoneForm.password == "") {
        this.$message({
          type: "error",
          message: "请输入密码!",
        });
        return;
      }
      if (this.phoneForm.password.length < 6) {
        this.$message({
          type: "error",
          message: "密码不能小于6位!",
        });
        return;
      }
      let data = {
        type: 2,
        mobile: this.phoneForm.phone,
      };
      this.f_setVerifTime();
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.phoneForm.codeId = res.data.codeId;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //修改密码
    async toUpdate() {
      let reg = /^1[3|4|5|7|8]\d{9}$/;
      //  手机号验证
      if (!reg.test(this.phoneForm.phone)) {
        this.$message({
          type: "error",
          message: "请正确输入手机号！",
        });
        return;
      }
      // 密码验证
      if (this.phoneForm.password == "") {
        this.$message({
          type: "error",
          message: "请输入密码！",
        });
        return;
      } else if (this.phoneForm.password.length < 6) {
        this.$message({
          type: "error",
          message: "密码不能少于6位",
        });
        return;
      }
      // 验证码验证
      if (this.phoneForm.codeId == "") {
        this.$message({
          type: "error",
          message: "验证码不能为空！",
        });
        return;
      }
      const data = {
        mobile: this.phoneForm.phone,
        codeId: this.phoneForm.codeId,
        code: this.phoneForm.code,
        newPassword: this.phoneForm.password,
      };
      const res = await update(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "修改成功！",
        });
        this.$store.commit("setIsShowLogin", true);
        this.$store.commit("setIsShowEdit", false);
        // this.login_flag = true;
        // this.forgot_flag = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Forget {
  width: 380px;
  height: 360px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 999;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  box-sizing: border-box;
  padding: 30px 0;
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
  }
  .header-logo {
    text-align: center;
    margin-bottom: 20px;
    img {
      width: 130px;
      height: 45px;
    }
  }
  .phone-login {
    margin-top: 30px;
    > div {
      display: flex;
      align-items: center;
      margin: 20px auto;
      background: #f3f5f9;
      border-radius: 18px;
      height: 35px;
      width: 75%;
      padding: 0 15px;
      box-sizing: border-box;

      input {
        font-size: 14px;
        padding-left: 10px;
        color: #808080;
        border: none;
        outline: none;
        background: none;
        flex: 1;
      }
    }
    .password {
      .view {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
    img {
      width: 20px;
      height: 20px;
    }
    .code {
      input {
        width: 150px;
      }
      .getCode {
        font-size: 12px;
        display: flex;
        align-items: center;
        color: #ff6500;
        width: 100px;
        height: 20px;
        border-left: 1px solid #ccc;
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
  .bottom {
    width: 75%;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    .tips {
      font-size: 13px;
      .light {
        color: #ff6500;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .btn {
      .edit {
        text-align: center;
        // width: 120px;
        margin: 0 auto;
        font-size: 14px;
        color: #fff;
        background: #ff6500;
        padding: 8px 20px;
        border-radius: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>