import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { isLogin } from "./utils/auth";
import "@/assets/scss/base.scss";
import "@/assets/scss/reset.scss";
import uploader from "vue-simple-uploader";
import VideoPlayer from "vue-video-player";
import Contextmenu from "vue-contextmenujs";
Vue.use(Contextmenu);
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

Vue.use(VideoPlayer);
Vue.use(uploader);

// 引入ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

//获取当前Token的方法挂载到全局
Vue.prototype.$isLogin = isLogin;

// 全局eventbus
let EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
});

// 初始化样式
import "normalize.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
