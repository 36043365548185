import Vue from 'vue'
import VueRouter from 'vue-router'
import versionTood from '../utils/versionUpdate'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originPush.call(this, location, () => {})
  } else {
    originPush.call(this, location, onComplete, onAbort)
  }
}
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originReplace.call(this, location, () => {})
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'Index',
    redirect: '/index/main',
    component: () => import('@/views/index/index'),
    children: [
      {
        path: 'main',
        name: 'Main',
        component: () => import('@/views/index/indexMain'),
      },
    ],
  },
  {
    path: '/print',
    name: 'Print',
    component: () => import('@/components/print'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Test'),
  },
  {
    path: '/collectpage',
    name: 'collectPage',
    component: () => import('@/views/collectPage/index'),
  },
  {
    path: '/taskList',
    name: 'TaskList',
    component: () => import('@/views/collectPage/collectTaskList'),
  },
  {
    path: '/createcollect',
    name: 'createCollect',
    component: () => import('@/views/createCollect/index'),
  },
  {
    path: '/indexS',
    name: 'IndexS',
    redirect: '/indexS/main',
    component: () => import('@/views/indexS/index'),
    children: [
      {
        path: 'main',
        name: 'Main',
        component: () => import('@/views/indexS/indexMain'),
      },
    ],
  },
  {
    path: '/newWord',
    name: 'NewWord',
    redirect: '/newWord/addWord',
    component: () => import('@/views/newWord/routerView'),
    children: [
      {
        path: 'addWord',
        name: 'MddWord',
        component: () => import('@/views/newWord/addWord'),
        redirect: '/newWord/addWord/aiwrite',
        children: [
          {
            path: 'textImage',
            name: 'TextImage',
            component: () =>
              import('@/views/newWord/addWordRight/textImage/textImageData'),
          },
          {
            path: 'library',
            name: 'Library',
            component: () =>
              import('@/views/newWord/addWordRight/library/libraryDate'),
          },
          {
            path: 'composing',
            name: 'Composing',
            component: () =>
              import('@/views/newWord/addWordRight/composing/composingIndex'),
          },
          {
            path: 'translate',
            name: 'Translate',
            component: () =>
              import('@/views/newWord/addWordRight/translate/translateData'),
          },
          {
            path: 'draftAIGC',
            name: 'DraftAIGC',
            component: () =>
              import(
                '@/views/newWord/addWordRight/AiWrite/oneClickWriting/writtenAi/DraftAIGC'
              ),
          },
          {
            path: 'aiwrite',
            name: 'AiWrite',
            component: () =>
              import('@/views/newWord/addWordRight/AiWrite/AiWriteIndex'),
          },
          {
            path: 'tools',
            name: 'Tools',
            component: () =>
              import('@/views/newWord/addWordRight/tools/toolsIndex'),
          },
          {
            path: 'disk',
            name: 'Disk',
            component: () => import('@/views/newWord/addWordRight/disk/index'),
          },
          {
            path: 'original',
            name: 'Original',
            component: () =>
              import('@/views/newWord/addWordRight/original/originalIndex'),
          },
          {
            path: 'collect',
            name: 'Collect',
            component: () =>
              import('@/views/newWord/addWordRight/collect/collect'),
          },
          // 专业服务
          {
            path: 'specialized',
            name: 'Specialized',
            component: () =>
              import('@/views/newWord/addWordRight/specialized/specialized'),
          },
          // 专业服务详情
          {
            path: 'ServiceDetails',
            name: 'ServiceDetails',
            component: () =>
              import(
                '@/views/newWord/addWordRight/specialized/ServiceDetails'
              ),
          },

          // {
          //   path: 'specialContent',
          //   name: 'specialContent',
          //   component: () =>
          //     import('@/views/newWord/addWordRight/specialized/content'),
          // },

          {
            path: 'writeAndSearch',
            name: 'WriteAndSearch',
            component: () =>
              import(
                '@/views/newWord/addWordRight/writeAndSearch/writeAndSearch'
              ),
          },
          {
            path: 'helpLibrary',
            name: 'HelpLibrary',
            component: () =>
              import(
                '@/views/newWord/addWordRight/helpLibrary/helpLibraryIndex'
              ),
          },
          
          // AI成文
          {
            path: 'Oneclickwriting',
            name: 'Oneclickwriting',
            component: () =>
              import(
                '@/views/newWord/addWordRight/Oneclickwriting/Oneclickwriting'
              ),
          },
          // AI成文详情
          // {
          //   path: 'OneclickwritingDilets',
          //   name: 'OneclickwritingDilets',
          //   component: () =>
          //     import(
          //       '@/views/newWord/addWordRight/Oneclickwriting/OneclickwritingDilets'
          //     ),
          // },
          {
            path: 'formation',
            name: 'formation',
            component: () =>
              import(
                '@/views/newWord/addWordRight/Oneclickwriting/formation.vue'
              ),
          },
          {
            path: 'Social',
            name: 'Social',
            component: () =>
              import(
                '@/views/newWord/addWordRight/Oneclickwriting/Social.vue'
              ),
          },

          {
            path: 'RaftAIGC',
            name: 'RaftAIGC',
            component: () =>
              import(
                '@/views/newWord/addWordRight/Oneclickwriting/DraftAIGC.vue'
              ),
          },
          // 评论列表
          {
            path: 'Comment',
            name: 'Comment',
            component: () =>
              import(
                '@/views/newWord/addWordRight/Oneclickwriting/Comment.vue'
              ),
          },
          //订单中心
          {
            name: 'OrderCenter',
            path: 'orderCenter',
            component: () =>
              import('@/views/newWord/addWordRight/order/OrderCenter'),
            meta: {
              show: true,
            },
          },
          // 成语券消费
          {
            name: 'OrderIdiom',
            path: 'OrderIdiom',
            component: () =>
              import('@/views/newWord/addWordRight/order/OrderIdiom'),
            meta: {
              show: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/newWordS',
    name: 'NewWord',
    redirect: '/newWordS/addWord',
    component: () => import('@/views/newWordS/routerView'),
    children: [
      {
        path: 'addWord',
        name: 'MddWord',
        component: () => import('@/views/newWordS/addWord'),
        redirect: '/newWordS/addWord/aiwrite',
        children: [
          {
            path: 'textImage',
            name: 'TextImage',
            component: () =>
              import('@/views/newWordS/addWordRight/textImage/textImageData'),
          },
          {
            path: 'library',
            name: 'Library',

            component: () =>
              import('@/views/newWordS/addWordRight/library/libraryDate'),
          },
          {
            path: 'translate',
            name: 'Translate',
            component: () =>
              import('@/views/newWordS/addWordRight/translate/translateData'),
          },
          {
            path: 'composing',
            name: 'Composing',
            component: () =>
              import('@/views/newWordS/addWordRight/composing/composingIndex'),
          },
          {
            path: 'aiwrite',
            name: 'AiWrite',
            component: () =>
              import('@/views/newWordS/addWordRight/AiWrite/AiWriteIndex'),
          },
          // {
          //   path: 'draftAIGC',
          //   name: 'DraftAIGC',
          //   component: () =>
          //     import(
          //       '@/views/newWordS/addWordRight/Oneclickwriting/writtenAi/DraftAIGC'
          //     ),
          // },
          {
            path: 'tools',
            name: 'Tools',
            component: () =>
              import('@/views/newWordS/addWordRight/tools/toolsIndex'),
          },
          {
            path: 'disk',
            name: 'Disk',
            component: () => import('@/views/newWordS/addWordRight/disk/index'),
          },
          {
            path: 'original',
            name: 'Original',
            component: () =>
              import('@/views/newWordS/addWordRight/original/originalIndex'),
          },

          {
            path: 'collect',
            name: 'Collect',
            component: () =>
              import('@/views/newWordS/addWordRight/collect/collect'),
          },
          // 专业服务
          {
            path: 'specialized',
            name: 'Specialized',
            component: () =>
              import('@/views/newWordS/addWordRight/specialized/specialized'),
          },
          // 专业服务详情
          {
            path: 'ServiceDetails',
            name: 'ServiceDetails',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/specialized/ServiceDetails'
              ),
          },

          {
            path: 'writeAndSearch',
            name: 'WriteAndSearch',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/writeAndSearch/writeAndSearch'
              ),
          },
          {
            path: 'helpLibrary',
            name: 'HelpLibrary',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/helpLibrary/helpLibraryIndex'
              ),
          },
          // AI成文
          {
            path: 'Oneclickwriting',
            name: 'Oneclickwriting',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/Oneclickwriting'
              ),
          },
          // AI成文详情
          // {
          //   path: 'OneclickwritingDilets',
          //   name: 'OneclickwritingDilets',
          //   component: () =>
          //     import(
          //       '@/views/newWordS/addWordRight/Oneclickwriting/OneclickwritingDilets'
          //     ),
          // },
          // 咨询服务详情
          {
            path: 'formation',
            name: 'formation',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/formation.vue'
              ),
          },
          {
            path: 'Social',
            name: 'Social',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/Social.vue'
              ),
          },
          {
            path: 'RaftAIGC',
            name: 'RaftAIGC',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/DraftAIGC.vue'
              ),
          },
          // 评论列表
          {
            path: 'Comment',
            name: 'Comment',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/Comment.vue'
              ),
          },

          // 评论列表
          {
            path: 'specializedComment',
            name: 'specializedComment',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/specialized/components/Comment.vue'
              ),
          },
          {
            path: 'paintingCommunity',
            name: 'paintingCommunity',
            component: () =>
              import('@/views/newWordS/addWordRight/paintingCommunity/index'),
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  versionTood.isNewVersion()
  let url = window.location.search
  let theRequest = new Object()

  if (url.includes('token=')) {
    let str = url.substr(1)
    console.log(str)
    let strs = str.split('&')

    console.log(strs)

    strs.forEach((item, i) => {
      theRequest[item.split('=')[0]] = item.split('=')[1]
    })
    console.log(theRequest)
    let obj = {
      access_token: theRequest.token,
      token_type: 'bearer',
    }

    localStorage.setItem('auth_token', JSON.stringify(theRequest.token))
    Cookies.set('auth_token', JSON.stringify(obj), {
      expires: 7,
      path: '/',
      domain: '.writemall.com',
    })
  }
  next()
})

export default router
