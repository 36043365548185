<template>
  <div class="VipCode" v-show="isShow">
    <div class="header">激活兑换</div>
    <div class="title">请输入VIP/SVIP激活码</div>
    <input
      class="input"
      type="text"
      placeholder="请输入激活码"
      v-model="code"
    />
    <div class="confirmButton" @click="confirmButton">确认</div>
    <div
      class="close"
      @click="
        code = '';
        isShow = false;
      "
    >
      <img :src="require('assets/images/ai-close.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { bindCardNumber } from "api/common";
import qs from "qs";
export default {
  data() {
    return {
      code: "",
      isShow: false,
    };
  },
  methods: {
    async confirmButton() {
      if (!this.code) {
        return this.$message.error("激活码不能为空");
      }
      if (this.code.length < 6) {
        return this.$message.error("激活码不能小于6位");
      }
      const params = {
        cardNumber: this.code,
      };
      const res = await bindCardNumber(qs.stringify(params));
      if (res.code == 200) {
        this.$message.success("激活成功");
        window.location.reload();
      } else {
        this.$message.error(res.message);
      }
    },
  },
  created() {
    this.$bus.$on("isShowVipCode", () => {
      this.isShow = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.VipCode {
  width: 322px;
  height: 337px;
  background-size: 100% 100%;
  background-image: url("~@/assets/images/vip_code.png");
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  .header {
    font-size: 19px;
    text-shadow: 0px 2px 4px rgba(163, 111, 46, 0.49);
    font-weight: bold;
    position: absolute;
    top: 22%;
    width: 100%;
    text-align: center;
    color: #fff;
  }
  .title {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;

    color: #754937;

    font-size: 16px;
  }

  .input {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    background: #fff;
    height: 56px;
    box-shadow: 0px 2px 4px 0px rgba(163, 111, 46, 0.2);
    border-radius: 10px;
    padding: 0 15px;
    box-sizing: border-box;
    color: #d49f6f;
    &::-webkit-input-placeholder {
      color: #d49f6f;
    }
  }
  .confirmButton {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("~@/assets/images/vip_code_btn.png");
    width: 277px;
    height: 66px;
    background-size: 100% 100%;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
    color: #fefefe;
    font-weight: bold;
  }
  .close {
    // background: #fff;
    position: absolute;
    bottom: -50px;
    width: 28px;
    height: 28px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>