<template>
  <div class="FreeGetVip">
    <!-- <div class="cy_logo">
      <img :src="require('assets/images/cy_logo.png')" alt="" />
      <span>省时省心给力</span>
    </div> -->
    <div class="btns">
      <div class="immediatelyGet" @click="immediatelyGet(1)">
        <!-- <img :src="require('assets/images/diamond_red.png')" alt="" /> -->
        <span>立刻邀请</span>
      </div>
      <div class="lookVipPower" @click="openVipPage">
        <span>邀请用户列表</span>
      </div>
    </div>
    <div class="dayNum">3天</div>
    <div class="tips">每多一个好友注册，增加10成语券。</div>
    <div class="footer">
      <img :src="require('assets/images/code4.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { findUserRecommendCode } from 'api/header'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      ishaveid: '',
      obj: '',
      code: '',
      characterNum: '',
      discountMsg: '',
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      // let user = JSON.parse(window.localStorage.getItem("vuex"));

      if (this.$store.state.userInfo) {
        this.isShowBtn = true
        this.ishaveid = this.$store.state.userInfo.haveid
      } else {
        this.isShowBtn = false
        this.ishaveid = ''
      }
      const res = await findUserRecommendCode()
      // console.log(res);
      if (res.code != 200) {
        return this.$message.success({
          message: res.message,
        })
      }
      this.obj = res.data
      this.code = res.data.code
      this.characterNum = 0
      this.discountMsg = res.data.discountMsg
    },

    immediatelyGet(val) {
      let oInput = document.createElement('textarea')
      val == 1
        ? (oInput.value =
            '我的邀请码是：' +
            this.code +
            '\n' +
            this.discountMsg +
            '\n' +
            '写手智能科技公司专属注册地址:' +
            this.obj.shareUrl)
        : (oInput.value = this.code)
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '邀请码已成功复制到剪切板',
        type: 'success',
      })
      oInput.remove()
    },
    openVipPage() {
      this.$parent.isShow = false
      this.$bus.$emit('showuserList')
      // this.$router.push('https://www.writemall.com/VIPcenter')
    },
    openShareGiftBag() {
      if (!getToken('auth_token')) {
        this.$message({
          message: '请先登录！',
          duration: 1500,
        })
        this.$store.commit('setIsShowLogin', true)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.FreeGetVip {
  width: 367px;
  height: 650px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  background-image: url('~@/assets/images/firend_bg.png');
  // font-family: "Source Han Sans CN";
  background-size: 100% 100%;
  z-index: 2001;
  .el-icon-close {
    cursor: pointer;
    position: absolute;
    color: #fff;
    top: 10px;
    right: 10px;
  }
  .cy_logo {
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 87%;
    padding-top: 20px;
    > img {
      width: 95px;
      height: 30px;
    }
    span {
      font-size: 14px;
      font-family: PingFang SC;
      color: #ffffff;
    }
  }
  .dayNum {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 39%;
    font-size: 43px;
    color: #d91104;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }
  .tips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;

    top: 55%;
    font-family: Source Han Sans CN;
    color: #fff3de;
    width: 100%;
    text-align: center;
  }
  .btns {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 60%;
    width: 60%;
    margin: 0 auto;
    .immediatelyGet {
      cursor: pointer;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      height: 50px;
      // margin: 10px 0;
      background-image: url('~@/assets/images/bg_01.png');
      background-size: 100% 100%;
      span {
        font-family: Microsoft YaHei;
        color: #f16c15;
        // text-shadow: 0px 0px 8px rgba(207, 118, 2, 0.98);
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        flex: 1;
      }
      img {
        height: 40px;
        width: 40px;
        margin-left: 20px;
        // margin: 0 20px;
        // margin-right: 30px;
      }
    }
    .lookVipPower {
      cursor: pointer;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      height: 50px;
      // margin: 10px 0;
      background-image: url('~@/assets/images/bg_02.png');
      background-size: 100% 100%;
      span {
        font-family: Microsoft YaHei;
        color: #fff3de;
        // text-shadow: 0px 0px 8px rgba(207, 118, 2, 0.98);
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        flex: 1;
      }
      img {
        height: 40px;
        width: 40px;
        margin-left: 20px;
        // margin: 0 20px;
      }
    }
  }
  .footer {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
    > img {
      width: 140px;
      height: 140px;
    }
    .label {
      font-family: PingFang SC;
      font-size: 13px;
      color: #ffffff;
    }
  }
}
</style>
