import request from "./request";
import crewordRequest from "./crewordRequest";

//发送验证码,获取验证码ID
export function getMobileAchCaptchas(data) {
  return request({
    url: "/common/getMobileAchCaptchas",
    method: "post",
    data,
  });
}

//发送验证码,获取验证码ID
export function getMobileAchCaptcha(data) {
  return request({
    url: "/common/getMobileAchCaptchas",
    method: "post",
    data,
  });
}

//激活会员码
export function bindCardNumber(data) {
  return request({
    url: "/card/bindCardNumber",
    method: "post",
    data,
  });
}

//校验验证码
export function checkMobileAchCaptchas(data) {
  return request({
    url: "/common/checkMobileAchCaptchas",
    method: "post",
    data,
  });
}

//上传文件
export function upload(data) {
  return request({
    url: "/common/upload",
    method: "post",
    data,
  });
}

// 下载文件
export function downloadFile(data) {
  return request({
    url: "/common/downloadFile",
    method: "post",
    data,
    responseType: "blob",
  });
}

//返回预览地址
export function previewUrl(data) {
  return request({
    url: "/common/preview",
    method: "post",
    data,
  });
}

//html to word
export function preserveWord(data) {
  return request({
    url: "/common/htmlToWordDownload",
    method: "post",
    data,
    responseType: "blob",
  });
}
// htmlToPdfDownload
//html to PDF
export function preservePDF(data) {
  return request({
    url: "/common/htmlToPdfDownload",
    method: "post",
    data,
    responseType: "blob",
  });
}
// 添加热度
export function addHotRecord(data) {
  return crewordRequest({
    url: "/common/addHotRecord",
    method: "post",
    data,
  });
}

//判断用户是否有免费次数
export function isHasFreeCount(data) {
  return crewordRequest({
    url: "/menuSetting/check",
    method: "post",
    data,
  });
}

export function userPortfolioInfo(data) {
  return request({
    url: "/portfolio/userPortfolioInfo",
    method: "post",
    data,
  });
}

export function bind() {
  return request({
    url: "/card/bind/3DaysPLUSCard",
    method: "post",
  });
}

export function check() {
  return request({
    url: "/card/check/3DaysPLUSCard",
    method: "post",
  });
}

// 支付宝支付
export function pcOrderPay(data) {
  return request({
    url: "/alipay/pcOrderPay",
    method: "post",
    data,
  });
}

export function getEsDateNum() {
  return crewordRequest({
    url: "/copyright/getEsDateNum",
    method: "post",
  });
}

export function getFindRightsAndInterests() {
  return crewordRequest({
    url: "/common/findRightsAndInterests",
    method: "post",
  });
}

export function website(data) {
  return crewordRequest({
    url: "/common/website/list?websiteType=" + data,
    method: "get",
  });
}
// website/type
export function websiteType() {
  return crewordRequest({
    url: "/common/website/type",
    method: "get",
  });
}

// website/type
export function hotList(params) {
  return crewordRequest({
    url: "/collection/hotList",
    method: "get",
    params,
  });
}
// website/type
export function typeList(params) {
  return crewordRequest({
    url: "/common/website/typeList",
    method: "get",
    params,
  });
}
