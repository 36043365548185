<template>
  <div class="buyTips" v-show="dialogVisible">
    <div class="close" @click="dialogVisible = false"></div>
    <div class="end"></div>
    <div class="vip">{{ $store.state.lockClickText.remark }}</div>
    <div class="tips">继续使用该功能</div>
    <div class="goBuy" @click="memberRenewal(4)">立即开通</div>
    <div class="invitation" @click="invitation">邀请好友获得VIP</div>
  </div>
</template>

<script>
import { getToken } from "utils/auth";
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {
    this.$bus.$on("buyTipsLock", () => {
      this.dialogVisible = true;
    });
  },
  methods: {
    memberRenewal() {
      this.dialogVisible = false;
      if (this.$isLogin()) {
        return;
      }
      // console.log(this.$store.state.lockClickText);
      const path = this.$route.path;

      if (path.includes("newWordS")) {
        if (this.$store.state.lockClickText.productId == 974) {
          window.open(
            `https://www.writemall.com/details/974/${
              this.$store.state.lockClickText.isVip - 1
            }?isHeaderShow=false`
          );
        } else {
          window.open(
            `https://www.writemall.com/details/1/${
              this.$store.state.lockClickText.isVip - 1
            }?isHeaderShow=false`
          );
        }
      } else {
        if (this.$store.state.lockClickText.productId == 974) {
          window.open(
            `https://www.writemall.com/details/974/${
              this.$store.state.lockClickText.isVip - 1
            }`
          );
        } else {
          window.open(
            `https://www.writemall.com/details/1/${
              this.$store.state.lockClickText.isVip - 1
            }`
          );
        }
      }

      // this.dialogVisible = false;
      // try {
      //   let TokenObj = getToken();
      //   let access_token = "";
      //   if (TokenObj) {
      //     TokenObj = JSON.parse(TokenObj);
      //     access_token = `${TokenObj.access_token}`;
      //   }
      //   // eslint-disable-next-line
      //   OpenBrowser(
      //     `https://www.writemall.com/details/1/${productId}?token=${access_token}`
      //   );
      // } catch (e) {
      //   console.log(e);
      // }

      // window.isifHaveBuyDialog();
      // console.log(val);
    },
    invitation() {
      this.dialogVisible = false;
      if (this.$isLogin()) {
        return;
      }

      // window.openShareGiftBag();
      this.$bus.$emit("showGrit");
    },
  },
  destroyed() {
    this.$bus.$off("buyTipsLock");
  },
};
</script>

<style lang="scss" scoped>
.buyTips {
  width: 347px;
  height: 448px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("~@/assets/images/buyTips.png");
  font-family: "Source Han Sans CN";
  background-size: 100% 100%;
  z-index: 9999;
  .close {
    top: 3%;
    right: 3%;
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;
    &::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 15px;
      background-color: #5c3d3d;
      position: absolute;
      transform: rotate(45deg);
    }
    &::after {
      content: "";
      display: inline-block;
      width: 2px;
      height: 15px;
      background-color: #5c3d3d;
      position: absolute;
      transform: rotate(-45deg);
    }
  }
  .end {
    opacity: 0.63;
    color: #ffffff;
    margin: 147px auto 0 auto;
    height: 18px;
    text-align: center;
  }
  .vip {
    color: #fbe3d1;
    font-size: 25px;
    font-weight: 800;
    text-shadow: 0px 2px 0px #c94c44;
    text-align: center;
    margin: 10px 0;
  }
  .tips {
    border: 1px solid linear-gradient(180deg, #c24038, #da786f);
    margin: 0 auto;
    width: 171px;
    line-height: 30px;
    text-align: center;
    height: 30px;
    background: #d14c45;
    border-radius: 12px;
    letter-spacing: 5px;
    font-size: 15px;
    color: #fbe3d1;
  }
  .goBuy {
    cursor: pointer;
    width: 233px;
    line-height: 50px;
    background-image: url("~@/assets/images/btn-bg.png");
    background-size: 100% 100%;
    text-align: center;
    line-height: 50px;
    letter-spacing: 3px;
    font-size: 20px;
    color: #5c3d3d;
    margin: 55px auto 10px auto;
  }
  .invitation {
    cursor: pointer;
    width: 233px;
    line-height: 50px;
    background-image: url("~@/assets/images/btn-bg.png");
    background-size: 100% 100%;
    text-align: center;
    line-height: 50px;
    letter-spacing: 3px;
    font-size: 20px;
    color: #5c3d3d;
    margin: 0 auto;
  }
}
</style>
